import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@entur/tab';
import { Heading3, Paragraph } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import { TextField, TextArea } from '@entur/form';
import { BannerAlertBox } from '@entur/alert';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Tabs style={{ marginRight: \'auto\' }}>\n  <TabList>\n    <Tab>Priser</Tab>\n    <Tab>Detaljer</Tab>\n    <Tab>Tom fane</Tab>\n  </TabList>\n  <TabPanels>\n    <TabPanel>\n      <Heading3>Informasjon</Heading3>\n      <Paragraph>\n        Et tab-panel kan inneholde masse informasjon, sortert og gruppert\n        etter kategori.\n      </Paragraph>\n      <Paragraph>\n        Man kan ha langt innhold eller kort innhold, og tab-panelet vil\n        tilpasse seg underveis.\n      </Paragraph>\n    </TabPanel>\n    <TabPanel>\n      <Heading3>Priser</Heading3>\n      <TextField defaultValue=\"99,00\" label=\"Pris\" />\n      <TextArea\n        defaultValue=\"Prisen er inkludert MVA\"\n        style={{ marginBottom: \'1rem\' }}\n      />\n      <PrimaryButton>Lagre</PrimaryButton>\n    </TabPanel>\n    <TabPanel />\n    <TabPanel>\n      <Heading3>Denne er tom</Heading3>\n    </TabPanel>\n  </TabPanels>\n</Tabs>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Tabs,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Heading3,
      Paragraph,
      PrimaryButton,
      TextField,
      TextArea,
      BannerAlertBox,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tabs style={{
        marginRight: 'auto'
      }} mdxType="Tabs">
    <TabList mdxType="TabList">
      <Tab mdxType="Tab">Priser</Tab>
      <Tab mdxType="Tab">Detaljer</Tab>
      <Tab mdxType="Tab">Tom fane</Tab>
    </TabList>
    <TabPanels mdxType="TabPanels">
      <TabPanel mdxType="TabPanel">
        <Heading3 mdxType="Heading3">Informasjon</Heading3>
        <Paragraph mdxType="Paragraph">
          Et tab-panel kan inneholde masse informasjon, sortert og gruppert
          etter kategori.
        </Paragraph>
        <Paragraph mdxType="Paragraph">
          Man kan ha langt innhold eller kort innhold, og tab-panelet vil
          tilpasse seg underveis.
        </Paragraph>
      </TabPanel>
      <TabPanel mdxType="TabPanel">
        <Heading3 mdxType="Heading3">Priser</Heading3>
        <TextField defaultValue="99,00" label="Pris" mdxType="TextField" />
        <TextArea defaultValue="Prisen er inkludert MVA" style={{
              marginBottom: '1rem'
            }} mdxType="TextArea" />
        <PrimaryButton mdxType="PrimaryButton">Lagre</PrimaryButton>
      </TabPanel>
      <TabPanel mdxType="TabPanel" />
      <TabPanel mdxType="TabPanel">
        <Heading3 mdxType="Heading3">Denne er tom</Heading3>
      </TabPanel>
    </TabPanels>
  </Tabs>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <p>{`Tab-pakken består av mange forskjellige komponenter, som lar deg komponere en Tab-opplevelse som passer dine behov.`}</p>
    <p>{`Strukturen til et tabs-panel ser slik ut:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Tabs>
  <TabList>
    <Tab>Option 1</Tab>
    <Tab>Option 2</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>Panel 1</TabPanel>
    <TabPanel>Panel 2</TabPanel>
  </TabPanels>
</Tabs>
`}</code></pre>
    <p>{`Under får du en beskrivelse av alle komponentene og hvordan du kan sette dem sammen.`}</p>
    <h3 {...{
      "id": "tabs"
    }}>{`Tabs`}</h3>
    <ImportStatement imports="Tabs" mdxType="ImportStatement" />
    <Props of={Tabs} mdxType="Props" />
    <p><inlineCode parentName="p">{`<Tabs />`}</inlineCode>{` brukes som en wrapper rundt tab-panelet.`}</p>
    <h4 {...{
      "id": "kontrollerbar"
    }}>{`Kontrollerbar`}</h4>
    <p>{`I utgangspunktet så er Tabs-paneler selvstyrt. Du kan spesifisere `}<inlineCode parentName="p">{`defaultIndex`}</inlineCode>{` for å si hvilket panel som skal være valgt by default.`}</p>
    <p>{`Om du ønsker, kan du også styre hvilket panel som er valgt programmatisk. Du kan sette valgt panel med `}<inlineCode parentName="p">{`index`}</inlineCode>{`-propen, og `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` til å lytte på når brukeren ønsker å endre det.`}</p>
    <p>{`Her er et eksempel der man går fra 1 tab til en annen programmatisk:`}</p>
    <Playground __position={3} __code={'() => {\n  const [currentIndex, setCurrentIndex] = React.useState(0)\n  return (\n    <Tabs\n      index={currentIndex}\n      onChange={newIndex => setCurrentIndex(newIndex)}\n    >\n      <TabList>\n        <Tab>En tab</Tab>\n        <Tab>En annen tab</Tab>\n      </TabList>\n      <TabPanels>\n        <TabPanel>\n          <PrimaryButton onClick={() => setCurrentIndex(1)} type=\"button\">\n            Gå til den andre taben\n          </PrimaryButton>\n        </TabPanel>\n        <TabPanel>Da er vi på tab nummer 2!</TabPanel>\n      </TabPanels>\n    </Tabs>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Tabs,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Heading3,
      Paragraph,
      PrimaryButton,
      TextField,
      TextArea,
      BannerAlertBox,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentIndex, setCurrentIndex] = React.useState(0);
        return <Tabs index={currentIndex} onChange={newIndex => setCurrentIndex(newIndex)} mdxType="Tabs">
        <TabList mdxType="TabList">
          <Tab mdxType="Tab">En tab</Tab>
          <Tab mdxType="Tab">En annen tab</Tab>
        </TabList>
        <TabPanels mdxType="TabPanels">
          <TabPanel mdxType="TabPanel">
            <PrimaryButton onClick={() => setCurrentIndex(1)} type="button" mdxType="PrimaryButton">
              Gå til den andre taben
            </PrimaryButton>
          </TabPanel>
          <TabPanel mdxType="TabPanel">Da er vi på tab nummer 2!</TabPanel>
        </TabPanels>
      </Tabs>;
      }}
    </Playground>
    <h3 {...{
      "id": "tablist"
    }}>{`TabList`}</h3>
    <ImportStatement imports="TabList" mdxType="ImportStatement" />
    <Props of={TabList} mdxType="Props" />
    <p><inlineCode parentName="p">{`<TabList />`}</inlineCode>{` er listen med tabs på toppen av siden. Denne kommer som første barn til `}<inlineCode parentName="p">{`<Tabs />`}</inlineCode>{`, og godtar flere `}<inlineCode parentName="p">{`<Tab />`}</inlineCode>{` barn.`}</p>
    <h3 {...{
      "id": "tab"
    }}>{`Tab`}</h3>
    <ImportStatement imports="Tab" mdxType="ImportStatement" />
    <Props of={Tab} mdxType="Props" />
    <p><inlineCode parentName="p">{`<Tab />`}</inlineCode>{` er hver av ark-fanene. De inneholder som regel bare en enkel tekst.`}</p>
    <h3 {...{
      "id": "tabpanels"
    }}>{`TabPanels`}</h3>
    <ImportStatement imports="TabPanels" mdxType="ImportStatement" />
    <Props of={TabPanels} mdxType="Props" />
    <p><inlineCode parentName="p">{`<TabPanels />`}</inlineCode>{` er wrapper-komponenten rundt alle panelene. Den tar ikke i mot noen spesielle props.`}</p>
    <h3 {...{
      "id": "tabpanel"
    }}>{`TabPanel`}</h3>
    <ImportStatement imports="TabPanel" mdxType="ImportStatement" />
    <Props of={TabPanel} mdxType="Props" />
    <p><inlineCode parentName="p">{`<TabPanel />`}</inlineCode>{` er hvert innholdspanel. De må ligge i samme rekkefølge som overskriftene i `}<inlineCode parentName="p">{`<TabList />`}</inlineCode>{`.`}</p>
    <p>{`En observant bruker vil oppdage at `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` mottar fokusmarkering ved tabbing til den.
Dette er fordi rammeverket som brukes for å bygge disse komponentene følger WAI-ARIA prinsippene for tilgjengeliget, og dette er derfor best-practice.
Interesserte kan lese mer om `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#tabpanel"
      }}>{`WAI-ARIA kravene til Tabs her.`}</a></p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Tabs eller faner lar oss designe grupperte brukergrensesnitt på en enkel måte. De benyttes ofte som en menynavigasjon innad på en side (f.eks. på nivå 3 eller 4), der valgt tab påvirker alt innhold under tabsrekken. De kan også benyttes til en avgrenset seksjon på en side, men da med en tydelig ramme rundt det de påvirker (?).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      